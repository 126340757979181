import { AppConstant } from '@config/app.config';
import { AuthenticationService } from 'src/app/core/service/authentication.service';

import { Router, NavigationEnd } from '@angular/router';

import { Input, OnInit, Component, OnDestroy } from '@angular/core';

import { map, filter } from 'rxjs/operators';

@Component({
    selector: 'app-submenu-component',
    templateUrl: './app.submenu.component.html',
})
export class AppSubMenuComponent implements OnInit, OnDestroy {
    @Input()
    public items;

    private sub = this.route.events
        .pipe(
            filter((event) => event instanceof NavigationEnd),
            map((event) => event as NavigationEnd)
        )
        .subscribe((event) => {
            let currentModule = event.url.split('/')[2];

            if (currentModule) {
                this.changeSubMenu(currentModule);
            } else {
                this.items = [];
            }
        });

    activeUrl;

    checked: boolean = false;
    hardCodedMenu = [
        {
            items: [
                {
                    label: 'Workbench',
                    icon: 'fas fa-toolbox',
                    routerLink: ['/admin/workbench'],
                    subMenu: [
                        // {
                        //     label: 'General Settings',
                        //     styleClass: '',
                        //     items: [],
                        // },
                        {
                            label: 'User & Menu Authorization',
                            styleClass: '',
                            items: [
                                {
                                    label: 'User Role',
                                    styleClass: '',
                                    routerLink: [
                                        '/admin/workbench/user-role/list',
                                    ],
                                },
                                {
                                    label: 'User',
                                    styleClass: '',
                                    routerLink: ['/admin/workbench/user/list'],
                                },
                                {
                                    label: 'Report Password',
                                    styleClass: '',
                                    routerLink: [
                                        '/admin/workbench/report-password/list',
                                    ],
                                },
                            ],
                        },
                        {
                            label: 'Transaction Configuration',
                            styleClass: '',
                            items: [
                                {
                                    label: 'Workflow Approval',
                                    styleClass: '',
                                    items: [
                                        {
                                            label: 'Workflow Category',
                                            styleClass: '',
                                            routerLink: [
                                                '/admin/workbench/workflow-category/list',
                                            ],
                                        },
                                        {
                                            label: 'Workflow Template',
                                            styleClass: '',
                                            routerLink: [
                                                '/admin/workbench/workflow-template/list',
                                            ],
                                        },
                                        {
                                            label: 'Approval Group',
                                            styleClass: '',
                                            routerLink: [
                                                '/admin/workbench/approval-group/list',
                                            ],
                                        },
                                    ],
                                },
                                {
                                    label: 'Transaction Settings',
                                    styleClass: '',
                                    routerLink: [
                                        '/admin/workbench/transaction-settings/list',
                                    ],
                                },
                                {
                                    label: 'Numbering Format',
                                    styleClass: '',
                                    routerLink: [
                                        '/admin/workbench/numbering-format/list',
                                    ],
                                },
                            ],
                        },
                        {
                            label: 'Portal Configuration',
                            styleClass: '',
                            items: [
                                {
                                    label: 'Announcement',
                                    styleClass: '',
                                    routerLink: [
                                        '/admin/workbench/announcement-panel/list',
                                    ],
                                },
                            ],
                        },
                        {
                            label: 'Migration',
                            styleClass: '',
                            items: [
                                {
                                    label: 'Migrations',
                                    styleClass: '',
                                    routerLink: [
                                        '/admin/workbench/migrations/upload',
                                    ],
                                },
                            ],
                        },
                        {
                            label: 'Template Configuration',
                            styleClass: '',
                            items: [
                                {
                                    label: 'Letter Template',
                                    styleClass: '',
                                    routerLink: [
                                        '/admin/workbench/letter-template/list',
                                    ],
                                },
                            ],
                        },
                        {
                            label: 'Reminder & Scheduler Settings',
                            styleClass: '',
                            items: [
                                {
                                    label: 'Manual Reminder Configurations',
                                    styleClass: '',
                                    routerLink: [
                                        '/admin/workbench/manual-reminder-configurations',
                                    ],
                                },
                                {
                                    label: 'Reminder Configuration',
                                    styleClass: '',
                                    routerLink: [
                                        '/admin/workbench/reminder-configuration/list',
                                    ],
                                },
                                {
                                    label: 'Scheduler Configuration',
                                    styleClass: '',
                                    routerLink: [
                                        '/admin/workbench/scheduler-configuration/list',
                                    ],
                                },
                            ],
                        },
                    ],
                },
            ],
        },
        {
            items: [
                {
                    label: 'Organization',
                    icon: 'fas fa-sitemap',
                    routerLink: ['/admin/organization'],
                    subMenu: [
                        {
                            label: 'Settings',
                            styleClass: '',
                            items: [
                                {
                                    label: 'Job Attribute',
                                    styleClass: '',
                                    routerLink: [
                                        '/admin/organization/settings/job-attribute/list-combination',
                                    ],
                                },
                                {
                                    label: 'Cost Center',
                                    styleClass: '',
                                    routerLink: [
                                        '/admin/organization/settings/cost-center/list',
                                    ],
                                },
                                {
                                    label: 'MPP',
                                    styleClass: '',
                                    routerLink: [
                                        '/admin/organization/settings/mpp/list',
                                    ],
                                },
                            ],
                        },
                        {
                            label: 'Activities',
                            styleClass: '',
                            items: [
                                {
                                    label: 'Company',
                                    styleClass: '',
                                    routerLink: [
                                        '/admin/organization/activities/company/list',
                                    ],
                                },
                                {
                                    label: 'Organization Master',
                                    styleClass: '',
                                    routerLink: [
                                        '/admin/organization/activities/organization-master-level/list',
                                    ],
                                },
                                {
                                    label: 'Organization Structure',
                                    styleClass: '',
                                    routerLink: [
                                        '/admin/organization/activities/organization-structure/list',
                                    ],
                                },
                                {
                                    label: 'Job Design',
                                    styleClass: '',
                                    items: [
                                        {
                                            label: 'Job Title',
                                            styleClass: '',
                                            routerLink: [
                                                '/admin/organization/activities/job-title/list',
                                            ],
                                        },
                                        {
                                            label: 'Job Level',
                                            styleClass: '',
                                            routerLink: [
                                                '/admin/organization/activities/job-level/list',
                                            ],
                                        },
                                        {
                                            label: 'Employee Type',
                                            styleClass: '',
                                            routerLink: [
                                                '/admin/organization/activities/employee-type/list',
                                            ],
                                        },
                                        {
                                            label: 'Job Position',
                                            styleClass: '',
                                            routerLink: [
                                                '/admin/organization/activities/job-position/list',
                                            ],
                                        },
                                    ],
                                },
                                {
                                    label: 'Work Location',
                                    styleClass: '',
                                    routerLink: [
                                        '/admin/organization/activities/work-location/list',
                                    ],
                                },
                                {
                                    label: 'Cost Center',
                                    styleClass: '',
                                    routerLink: [
                                        '/admin/organization/activities/cost-center/list',
                                    ],
                                },
                                {
                                    label: 'Man Power Planning',
                                    styleClass: '',
                                    items: [
                                        {
                                            label: 'Period',
                                            styleClass: '',
                                            routerLink: [
                                                '/admin/organization/activities/mpp-period/list',
                                            ],
                                        },
                                        {
                                            label: 'Request',
                                            styleClass: '',
                                            routerLink: [
                                                '/admin/organization/activities/mpp-request/list',
                                            ],
                                        },
                                        {
                                            label: 'Balance Display',
                                            styleClass: '',
                                            routerLink: [
                                                '/admin/organization/activities/mpp-balance-display/list',
                                            ],
                                        },
                                    ],
                                },
                                // {
                                //     label: 'Job Attribute Requests',
                                //     styleClass: '',
                                //     items: [
                                //         {
                                //             label: 'Organization Request',
                                //             styleClass: '',
                                //             routerLink: [
                                //                 '/admin/organization/activities/organization-request/list',
                                //             ],
                                //         },
                                //     ],
                                // },
                            ],
                        },
                    ],
                },
            ],
        },
        {
            items: [
                {
                    label: 'Workforce',
                    icon: 'fas fa-users',
                    routerLink: ['/admin/workforce'],
                    subMenu: [
                        {
                            label: 'Settings',
                            styleClass: '',
                            items: [
                                {
                                    label: 'General Settings',
                                    styleClass: '',
                                    items: [
                                        {
                                            label: 'Bank Master',
                                            styleClass: '',
                                            routerLink: [
                                                '/admin/workforce/settings/bank-account/list',
                                            ],
                                        },
                                        {
                                            label: 'Digital Signature',
                                            styleClass: '',
                                            routerLink: [
                                                '/admin/workforce/settings/digital-signature/list',
                                            ],
                                        },
                                        {
                                            label: 'Retirement Period',
                                            styleClass: '',
                                            routerLink: [
                                                '/admin/workforce/settings/retirement-period/list',
                                            ],
                                        },
                                    ],
                                },
                                {
                                    label: 'Numbering',
                                    styleClass: '',
                                    items: [
                                        {
                                            label: 'Employee Number Format',
                                            styleClass: '',
                                            routerLink: [
                                                '/admin/workforce/settings/numbering/general-settings-member-by/detail',
                                            ],
                                        },
                                        {
                                            label: 'Employee Family Patient ID',
                                            styleClass: '',
                                            routerLink: [
                                                '/admin/workforce/settings/numbering-family-id/list',
                                            ],
                                        },
                                    ],
                                },
                                {
                                    label: 'Employee Data Field Configuration',
                                    styleClass: '',
                                    routerLink: [
                                        '/admin/workforce/settings/employee-data-field-configuration/list',
                                    ],
                                },
                                {
                                    label: 'Master Data Configuration',
                                    styleClass: '',
                                    items: [
                                        {
                                            label: 'Person',
                                            styleClass: '',
                                            routerLink: [
                                                '/admin/workforce/settings/person/relationship/list',
                                            ],
                                        },
                                        {
                                            label: 'Residential',
                                            styleClass: '',
                                            routerLink: [
                                                '/admin/workforce/settings/data-configuration-master-residence/state-list',
                                            ],
                                        },
                                        {
                                            label: 'Education',
                                            styleClass: '',
                                            routerLink: [
                                                '/admin/workforce/settings/data-configuration-education-master/list',
                                            ],
                                        },
                                        {
                                            label: 'Language',
                                            styleClass: '',
                                            routerLink: [
                                                '/admin/workforce/settings/language/list',
                                            ],
                                        },
                                        {
                                            label: 'Currency',
                                            styleClass: '',
                                            routerLink: [
                                                '/admin/workforce/settings/data-configuration-currency/list',
                                            ],
                                        },
                                    ],
                                },
                                {
                                    label: 'Movement Master',
                                    styleClass: '',
                                    items: [
                                        {
                                            label: 'Movement Type',
                                            styleClass: '',
                                            routerLink: [
                                                '/admin/workforce/settings/movement-type/list',
                                            ],
                                        },
                                        {
                                            label: 'Movement Type Setting',
                                            styleClass: '',
                                            routerLink: [
                                                '/admin/workforce/settings/movement-type-setting/list',
                                            ],
                                        },
                                    ],
                                    routerLink: [],
                                },
                                {
                                    label: 'Termination Master',
                                    styleClass: '',
                                    items: [
                                        {
                                            label: 'Termination Type',
                                            styleClass: '',
                                            routerLink: [
                                                '/admin/workforce/settings/employee-document-termination/list',
                                            ],
                                        },
                                    ],
                                    routerLink: [],
                                },
                                {
                                    label: 'Onboarding Configuration',
                                    styleClass: '',
                                    routerLink: [
                                        '/admin/workforce/settings/on-boarding-configuration/list',
                                    ],
                                },
                                {
                                    label: 'Offboarding Configuration',
                                    styleClass: '',
                                    routerLink: [
                                        '/admin/workforce/settings/offboarding-configuration/list',
                                        ,
                                    ],
                                },
                                {
                                    label: 'Employee Reward Master',
                                    styleClass: '',
                                    routerLink: [
                                        '/admin/workforce/settings/employee-reward-master/list',
                                    ],
                                },
                                {
                                    label: 'Employee Punishment Master',
                                    styleClass: '',
                                    items: [
                                        {
                                            label: 'Punishment Type',
                                            styleClass: '',
                                            routerLink: [
                                                '/admin/workforce/settings/employee-punishment-master/list',
                                            ],
                                        },
                                        {
                                            label: 'Punishment Level',
                                            styleClass: '',
                                            routerLink: [
                                                '/admin/workforce/settings/employee-punishment-master/levels/list',
                                            ],
                                        },
                                    ],
                                    routerLink: [],
                                },
                                {
                                    label: 'Employee Asset',
                                    styleClass: '',
                                    routerLink: [
                                        '/admin/workforce/settings/employee/asset/list',
                                    ],
                                },
                                {
                                    label: 'Employee Document',
                                    styleClass: '',
                                    routerLink: [
                                        '/admin/workforce/settings/employee-document/document-master/list',
                                    ],
                                },
                                {
                                    label: 'Vendor Master',
                                    styleClass: '',
                                    routerLink: [
                                        '/admin/workforce/settings/vendor-master/list',
                                    ],
                                },
                                {
                                    label: 'Q&A Master',
                                    styleClass: '',
                                    items: [
                                        {
                                            label: 'Q&A Template',
                                            styleClass: '',
                                            routerLink: [
                                                '/admin/workforce/settings/qna-template/list',
                                            ],
                                        },
                                        {
                                            label: 'Q&A Template Type',
                                            styleClass: '',
                                            routerLink: [
                                                '/admin/workforce/settings/interview-qna-template-type/exit-interview/list',
                                            ],
                                        },
                                        {
                                            label: 'Master Measurement',
                                            styleClass: '',
                                            routerLink: [
                                                '/admin/workforce/settings/measurement-master/list',
                                            ],
                                        },
                                    ],
                                },
                                {
                                    label: 'Action Reason',
                                    styleClass: '',
                                    routerLink: [
                                        '/admin/workforce/settings/action-reason/list',
                                    ],
                                },
                            ],
                        },
                        {
                            label: 'Activities',
                            styleClass: '',
                            items: [
                                {
                                    label: 'Employee Information',
                                    styleClass: '',
                                    routerLink: [
                                        '/admin/workforce/activities/employee-information/list',
                                    ],
                                },
                                {
                                    label: 'New Employee Entry',
                                    styleClass: '',
                                    routerLink: [
                                        '/admin/workforce/activities/new-employee-entry/list',
                                    ],
                                },
                                {
                                    label: 'Employee Movement',
                                    styleClass: '',
                                    items: [
                                        {
                                            label: 'Onboarding',
                                            styleClass: '',
                                            routerLink: [
                                                '/admin/workforce/activities/employee-movement/on-boarding/list',
                                            ],
                                        },
                                        {
                                            label: 'Individual Movement',
                                            styleClass: '',
                                            routerLink: [
                                                '/admin/workforce/activities/employee-movement/individual-movement/list-publish',
                                            ],
                                        },
                                        {
                                            label: 'Massive Movement',
                                            styleClass: '',
                                            routerLink: [
                                                '/admin/workforce/activities/employee-movement/massive-movement/list-publish',
                                            ],
                                        },
                                        {
                                            label: 'Acting Job',
                                            styleClass: '',
                                            routerLink: [
                                                '/admin/workforce/activities/employee-movement/acting-job/list',
                                            ],
                                        },
                                    ],
                                },
                                {
                                    label: 'Employee Termination',
                                    styleClass: '',
                                    items: [
                                        {
                                            label: 'Individual Termination',
                                            styleClass: '',
                                            routerLink: [
                                                '/admin/workforce/activities/employee-termination/individual-termination/list',
                                            ],
                                        },
                                        {
                                            label: 'Massive Termination',
                                            styleClass: '',
                                            routerLink: [
                                                '/admin/workforce/activities/massive-termination/list',
                                            ],
                                        },
                                        {
                                            label: 'Termination Request',
                                            styleClass: '',
                                            routerLink: [
                                                '/admin/workforce/activities/employee-termination-request/list',
                                            ],
                                        },
                                        {
                                            label: 'Off Boarding',
                                            styleClass: '',
                                            routerLink: [
                                                '/admin/workforce/activities/employee-termination-offboarding/list',
                                            ],
                                        },
                                        // {
                                        //     label: 'Exit Interview',
                                        //     styleClass: '',
                                        //     routerLink: [
                                        //         '/admin/workforce/activities/exit-interview/list',
                                        //     ],
                                        // },
                                    ],
                                },
                                {
                                    label: 'Employee Review',
                                    styleClass: '',
                                    items: [
                                        {
                                            label: 'Employee Movement Review',
                                            styleClass: '',
                                            routerLink: [
                                                '/admin/workforce/activities/employee-review/movement-review/list',
                                            ],
                                        },
                                        {
                                            label: 'Temporary Employee Review',
                                            styleClass: '',
                                            routerLink: [
                                                '/admin/workforce/activities/employee-review/temporary-review/list',
                                            ],
                                        },
                                    ],
                                },
                                {
                                    label: 'Reward Administration',
                                    styleClass: '',
                                    items: [
                                        {
                                            label: 'Reward Received',
                                            styleClass: '',
                                            routerLink: [
                                                '/admin/workforce/activities/reward-administration/reward-received/list',
                                            ],
                                        },
                                        {
                                            label: 'Reward Request',
                                            styleClass: '',
                                            routerLink: [
                                                '/admin/workforce/activities/reward-administration/reward-request/list',
                                            ],
                                        },
                                    ],
                                },
                                {
                                    label: 'Punishment Administration',
                                    styleClass: '',
                                    items: [
                                        {
                                            label: 'Punishment Received',
                                            styleClass: '',
                                            routerLink: [
                                                '/admin/workforce/activities/punishment-received/list',
                                            ],
                                        },
                                        {
                                            label: 'Punishment Request',
                                            styleClass: '',
                                            routerLink: [
                                                '/admin/workforce/activities/punishment-request/list',
                                            ],
                                        },
                                    ],
                                },
                                {
                                    label: 'Asset Management',
                                    styleClass: '',
                                    items: [
                                        {
                                            label: 'Asset Assignment Request',
                                            styleClass: '',
                                            routerLink: [
                                                '/admin/workforce/activities/asset-assignment-request/list',
                                            ],
                                        },
                                        {
                                            label: 'Asset Return Request',
                                            styleClass: '',
                                            routerLink: [
                                                '/admin/workforce/activities/asset-return-request/list',
                                            ],
                                        },
                                        {
                                            label: 'Employee Asset',
                                            styleClass: '',
                                            routerLink: [
                                                '/admin/workforce/activities/employee-asset/list',
                                            ],
                                        },
                                    ],
                                },
                                {
                                    label: 'Document Management',
                                    styleClass: '',
                                    items: [
                                        {
                                            label: 'Document Entry',
                                            styleClass: '',
                                            routerLink: [
                                                '/admin/workforce/activities/document-entry-request/list',
                                            ],
                                        },
                                        {
                                            label: 'Document Return Request',
                                            styleClass: '',
                                            routerLink: [
                                                '/admin/workforce/activities/document-return-request/list',
                                            ],
                                        },
                                        {
                                            label: 'Employee Document',
                                            styleClass: '',
                                            routerLink: [
                                                '/admin/workforce/activities/employee-document/list',
                                            ],
                                        },
                                    ],
                                },
                                {
                                    label: 'Employee Letters',
                                    styleClass: '',
                                    items: [
                                        // {
                                        //     label: 'Letter Administration',
                                        //     styleClass: '',
                                        //     routerLink: [
                                        //         '/admin/workforce/activities/letter/administration',
                                        //     ],
                                        // },
                                        {
                                            label: 'Curriculum Vitae',
                                            styleClass: '',
                                            routerLink: [
                                                '/admin/workforce/activities/letter/curriculum-vitae',
                                            ],
                                        },
                                        {
                                            label: 'Reference Letter',
                                            styleClass: '',
                                            routerLink: [
                                                '/admin/workforce/activities/letter/employee-reference',
                                            ],
                                        },
                                    ],
                                },
                                {
                                    label: 'Employee Request',
                                    styleClass: '',
                                    items: [
                                        {
                                            label: 'Employee Data Update',
                                            styleClass: '',
                                            routerLink: [
                                                '/admin/workforce/activities/employee-request/employee-data-update/list',
                                            ],
                                        },
                                        {
                                            label: 'Employee Credential Update',
                                            styleClass: '',
                                            routerLink: [
                                                '/admin/workforce/activities/employee-credential/list',
                                            ],
                                        },
                                    ],
                                },
                            ],
                        },
                        {
                            label: 'Reports',
                            styleClass: '',
                            items: [
                                {
                                    label: 'Employee',
                                    styleClass: '',
                                    items: [
                                        {
                                            label: 'Contract Report',
                                            styleClass: '',
                                            routerLink: [
                                                '/admin/workforce/reports/contract-expired',
                                            ],
                                        },
                                        {
                                            label: 'Service Period Report',
                                            styleClass: '',
                                            routerLink: [
                                                '/admin/workforce/reports/service-period',
                                            ],
                                        },
                                    ],
                                },
                                {
                                    label: 'Movement',
                                    styleClass: '',
                                    items: [
                                        {
                                            label: 'Movement Report',
                                            styleClass: '',
                                            routerLink: [
                                                '/admin/workforce/reports/movement',
                                            ],
                                        },
                                    ],
                                },
                                {
                                    label: 'Termination',
                                    styleClass: '',
                                    items: [
                                        {
                                            label: 'Termination Report',
                                            styleClass: '',
                                            routerLink: [
                                                '/admin/workforce/reports/termination',
                                            ],
                                        },
                                        // {
                                        //     label: 'Exit Interview Report',
                                        //     styleClass: '',
                                        //     routerLink: [
                                        //         '/admin/workforce/reports/exit-interview',
                                        //     ],
                                        // },
                                    ],
                                },
                                {
                                    label: 'Reward',
                                    styleClass: '',
                                    items: [
                                        {
                                            label: 'Award Report',
                                            styleClass: '',
                                            routerLink: [
                                                '/admin/workforce/reports/reward',
                                            ],
                                        },
                                    ],
                                },
                                {
                                    label: 'Punishment',
                                    styleClass: '',
                                    items: [
                                        {
                                            label: 'Punishment Report',
                                            styleClass: '',
                                            routerLink: [
                                                '/admin/workforce/reports/punishment',
                                            ],
                                        },
                                    ],
                                },
                            ],
                        },
                    ],
                },
            ],
        },
        {
            items: [
                {
                    label: 'Time & Attendance',
                    icon: 'fas fa-clock',
                    routerLink: ['/admin/time'],
                    subMenu: [
                        {
                            label: 'Dashboard',
                            styleClass: '',
                            routerLink: ['/admin/time/dashboard'],
                        },
                        {
                            label: 'Settings',
                            styleClass: '',
                            items: [
                                {
                                    label: 'General Setting',
                                    styleClass: '',
                                    items: [
                                        {
                                            label: 'Time Policy',
                                            styleClass: '',
                                            routerLink: [
                                                '/admin/time/settings/time-policy/list',
                                            ],
                                        },
                                        {
                                            label: 'Leave & Permit Policy',
                                            styleClass: '',
                                            routerLink: [
                                                '/admin/time/settings/leave-permit-policy/detail',
                                            ],
                                        },
                                        {
                                            label: 'Color',
                                            styleClass: '',
                                            routerLink: [
                                                '/admin/time/settings/color-master/list',
                                            ],
                                        },
                                        {
                                            label: 'Attendance Code',
                                            styleClass: '',
                                            routerLink: [
                                                '/admin/time/settings/attendance-code/list',
                                            ],
                                        },
                                        {
                                            label: 'Overtime Policy',
                                            styleClass: '',
                                            routerLink: [
                                                '/admin/time/settings/overtime-policy/list',
                                            ],
                                        },
                                        {
                                            label: 'Attendance',
                                            items: [
                                                {
                                                    label: 'Attendance Location',
                                                    styleClass: '',
                                                    routerLink: [
                                                        '/admin/time/settings/attendance/attendance-location/list',
                                                    ],
                                                },
                                            ],
                                        },
                                    ],
                                    routerLink: [],
                                },

                                {
                                    label: 'Schedule',
                                    styleClass: '',
                                    items: [
                                        {
                                            label: 'Shift',
                                            styleClass: '',
                                            routerLink: [
                                                '/admin/time/settings/shift/list',
                                            ],
                                        },
                                        {
                                            label: 'Workgroup',
                                            styleClass: '',
                                            routerLink: [
                                                '/admin/time/settings/workgroup/list',
                                            ],
                                        },
                                    ],
                                    routerLink: [],
                                },
                                {
                                    label: 'Overtime',
                                    styleClass: '',
                                    items: [
                                        {
                                            label: 'Overtime Category',
                                            styleClass: '',
                                            routerLink: [
                                                '/admin/time/settings/overtime/category/list',
                                            ],
                                        },
                                        {
                                            label: 'Overtime Settings',
                                            styleClass: '',
                                            routerLink: [
                                                '/admin/time/settings/overtime/settings/list',
                                            ],
                                        },
                                        {
                                            label: 'Overtime Calculation',
                                            styleClass: '',
                                            routerLink: [
                                                '/admin/time/settings/overtime-calculation/list',
                                            ],
                                        },
                                    ],
                                },
                                {
                                    label: 'Leave',
                                    styleClass: '',
                                    items: [
                                        {
                                            label: 'Leave Setting',
                                            styleClass: '',
                                            routerLink: [
                                                '/admin/time/settings/leave-settings/list',
                                            ],
                                        },
                                        {
                                            label: 'Leave Formula',
                                            styleClass: '',
                                            routerLink: [
                                                '/admin/time/settings/leave-formula/list',
                                            ],
                                        },
                                    ],
                                },
                                {
                                    label: 'Daily Wages',
                                    styleClass: '',
                                    items: [
                                        {
                                            label: 'Overtime Allowance',
                                            styleClass: '',
                                            routerLink: [
                                                '/admin/time/settings/daily-wages/overtime-allowance/list',
                                            ],
                                        },
                                        // {
                                        //     label: 'Attendance Payment',
                                        //     styleClass: '',
                                        //     routerLink: [
                                        //         '/admin/time/settings/daily-wages/attendance-payment/list',
                                        //     ],
                                        // },
                                        // {
                                        //     label: 'Shift Allowance',
                                        //     styleClass: '',
                                        //     routerLink: [
                                        //         '/admin/time/settings/daily-wages/shift-allowance/list',
                                        //     ],
                                        // },
                                    ],
                                },
                                {
                                    label: 'Permit Settings',
                                    styleClass: '',
                                    routerLink: [
                                        '/admin/time/settings/permit-settings/list',
                                    ],
                                },
                                {
                                    label: 'Terminal Settings',
                                    styleClass: '',
                                    routerLink: [
                                        '/admin/time/settings/terminal-settings/list',
                                    ],
                                },
                            ],
                        },
                        {
                            label: 'Activities',
                            styleClass: '',
                            items: [
                                {
                                    label: 'Schedule',
                                    styleClass: '',
                                    items: [
                                        {
                                            label: 'Public Holiday Calendar',
                                            styleClass: '',
                                            routerLink: [
                                                '/admin/time/schedule/public-holiday-calendar',
                                            ],
                                        },
                                        {
                                            label: 'Employee Calendar',
                                            styleClass: '',
                                            routerLink: [
                                                '/admin/time/schedule/employee-calendar',
                                            ],
                                        },
                                        {
                                            label: 'Change Schedule',
                                            styleClass: '',
                                            routerLink: [
                                                '/admin/time/activities/change-schedule/list',
                                            ],
                                        },
                                    ],
                                },
                                {
                                    label: 'Attendance',
                                    styleClass: '',
                                    items: [
                                        {
                                            label: 'Raw Attendance',
                                            styleClass: '',
                                            routerLink: [
                                                '/admin/time/activities/attendance/raw-attendance/list',
                                            ],
                                        },
                                        {
                                            label: 'Timesheet',
                                            styleClass: '',
                                            routerLink: [
                                                '/admin/time/activities/attendance/timesheet/list',
                                            ],
                                        },
                                        {
                                            label: 'Attendance Correction',
                                            styleClass: '',
                                            routerLink: [
                                                '/admin/time/activities/attendance-correction/list',
                                            ],
                                        },
                                    ],
                                },
                                {
                                    label: 'Overtime',
                                    styleClass: '',
                                    items: [
                                        {
                                            label: 'Overtime Plan',
                                            styleClass: '',
                                            routerLink: [
                                                '/admin/time/activities/overtime-plan/list',
                                            ],
                                        },
                                        {
                                            label: 'Overtime Actual',
                                            styleClass: '',
                                            routerLink: [
                                                '/admin/time/activities/overtime-actual/list',
                                            ],
                                        },
                                        {
                                            label: 'Overtime Allowance Confirmation',
                                            styleClass: '',
                                            routerLink: [
                                                '/admin/time/activities/overtime-allowance-confirmation/list',
                                            ],
                                        },
                                    ],
                                },
                                {
                                    label: 'Leave & Permit',
                                    styleClass: '',
                                    items: [
                                        {
                                            label: 'Generate Leave Balance',
                                            styleClass: '',
                                            routerLink: [
                                                '/admin/time/activities/leave-generate-balance/list',
                                            ],
                                        },
                                        {
                                            label: 'Leave Request',
                                            styleClass: '',
                                            routerLink: [
                                                '/admin/time/activities/leave-request/list',
                                            ],
                                        },
                                        {
                                            label: 'Adjustment Balance',
                                            styleClass: '',
                                            routerLink: [
                                                '/admin/time/activities/adjustment-balance/list',
                                            ],
                                        },
                                        {
                                            label: 'Permit Request',
                                            styleClass: '',
                                            routerLink: [
                                                '/admin/time/activities/permit-request/list',
                                            ],
                                        },
                                        {
                                            label: 'Leave Payment',
                                            styleClass: '',
                                            routerLink: [
                                                '/admin/time/activities/leave-payment/list',
                                            ],
                                        },
                                        {
                                            label: 'Employee Balance',
                                            styleClass: '',
                                            routerLink: [
                                                '/admin/time/activities/employee-balance/list',
                                            ],
                                        },
                                    ],
                                    routerLink: [],
                                },
                                {
                                    label: 'Daily Wages Process',
                                    styleClass: '',
                                    routerLink: [
                                        '/admin/time/activities/daily-wages-process/time-calculation',
                                    ],
                                },
                            ],
                        },
                        {
                            label: 'Reports',
                            styleClass: '',
                            items: [
                                {
                                    label: 'Attendance',
                                    styleClass: '',
                                    items: [
                                        {
                                            label: 'Daily Attendance Report',
                                            styleClass: '',
                                            routerLink: [
                                                '/admin/time/reports/daily-attendance',
                                            ],
                                        },
                                        {
                                            label: 'Attendance Report',
                                            styleClass: '',
                                            routerLink: [
                                                '/admin/time/reports/attendance-report',
                                            ],
                                        },
                                        // {
                                        //     label: 'Performance Attendance Report',
                                        //     styleClass: '',
                                        //     routerLink: [],
                                        // },
                                        // {
                                        //     label: 'Attendance Code Summary Report',
                                        //     styleClass: '',
                                        //     routerLink: [],
                                        // },
                                        {
                                            label: 'Late In & Early Out Report',
                                            styleClass: '',
                                            routerLink: [
                                                '/admin/time/reports/late-in-early-out-report',
                                            ],
                                        },
                                        {
                                            label: 'Calculation Late In & Early Out Report',
                                            styleClass: '',
                                            routerLink: [
                                                '/admin/time/reports/calculation-late-in-early-out-report',
                                            ],
                                        },
                                        {
                                            label: 'Timesheet Report',
                                            styleClass: '',
                                            routerLink: [
                                                '/admin/time/reports/timesheet',
                                            ],
                                        },
                                        {
                                            label: 'Time Summary Report',
                                            styleClass: '',
                                            routerLink: [
                                                '/admin/time/reports/time-summary',
                                            ],
                                        },
                                        {
                                            label: 'Performance Attendance Report',
                                            styleClass: '',
                                            routerLink: [
                                                '/admin/time/reports/performance-attendance-report',
                                            ],
                                        },
                                    ],
                                },
                                {
                                    label: 'Overtime',
                                    styleClass: '',
                                    items: [
                                        {
                                            label: 'Supplement Report',
                                            styleClass: '',
                                            routerLink: [
                                                '/admin/time/reports/supplement-report',
                                            ],
                                        },
                                        {
                                            label: 'Factory Entry Permit Report',
                                            styleClass: '',
                                            routerLink: [
                                                '/admin/time/reports/factory-entry-permit-report',
                                            ],
                                        },
                                        {
                                            label: 'Overtime Report',
                                            styleClass: '',
                                            routerLink: [
                                                '/admin/time/reports/overtime-report',
                                            ],
                                        },
                                        {
                                            label: 'Calculation Overtime Report',
                                            styleClass: '',
                                            routerLink: [
                                                '/admin/time/reports/calculation-overtime-report',
                                            ],
                                        },
                                    ],
                                },
                                {
                                    label: 'Leave & Permit',
                                    styleClass: '',
                                    items: [
                                        {
                                            label: 'Permit Request Report',
                                            styleClass: '',
                                            routerLink: [
                                                '/admin/time/reports/permit-report',
                                            ],
                                        },
                                        {
                                            label: 'Leave Request Report',
                                            styleClass: '',
                                            routerLink: [
                                                '/admin/time/reports/leave-report',
                                            ],
                                        },
                                        {
                                            label: 'Leave Recapitulation Report',
                                            styleClass: '',
                                            routerLink: [
                                                '/admin/time/reports/leave-recapitulation-report',
                                            ],
                                        },
                                        {
                                            label: 'Leave Payment Report',
                                            styleClass: '',
                                            routerLink: [
                                                '/admin/time/reports/leave-payment-report',
                                            ],
                                        },
                                        {
                                            label: 'Leave Compensation Report',
                                            styleClass: '',
                                            routerLink: [
                                                '/admin/time/reports/leave-compensation-report',
                                            ],
                                        },
                                        {
                                            label: 'Leave & Permit Balance Report',
                                            styleClass: '',
                                            routerLink: [
                                                '/admin/time/reports/leave-permit-balance-report',
                                            ],
                                        },
                                    ],
                                },
                            ],
                        },
                    ],
                },
            ],
        },
        {
            items: [
                {
                    label: 'Reimbursement',
                    icon: 'fas fa-hand-holding-usd',
                    routerLink: ['/admin/reimbursement'],
                    subMenu: [
                        {
                            label: 'Settings',
                            styleClass: '',
                            items: [
                                {
                                    label: 'General Setting',
                                    styleClass: '',
                                    items: [
                                        {
                                            label: 'Medical Type',
                                            styleClass: '',
                                            routerLink: [
                                                '/admin/reimbursement/settings/medical-type/list-group',
                                            ],
                                        },
                                        {
                                            label: 'Healthcare Institution',
                                            styleClass: '',
                                            routerLink: [
                                                '/admin/reimbursement/settings/healthcare-institution-type/list',
                                            ],
                                        },
                                    ],
                                },
                                {
                                    label: 'Medical Reimbursement',
                                    styleClass: '',
                                    items: [
                                        {
                                            label: 'Medical Reimburse Configuration',
                                            styleClass: '',
                                            routerLink: [
                                                '/admin/reimbursement/settings/medical-reimburse-configuration/list',
                                            ],
                                        },
                                        {
                                            label: 'Medical Reimburse Regulation',
                                            styleClass: '',
                                            items: [
                                                {
                                                    label: 'Medical Rule',
                                                    styleClass: '',
                                                    routerLink: [
                                                        '/admin/reimbursement/settings/medical-rule/list',
                                                    ],
                                                },
                                                {
                                                    label: 'Medical Rule Detail',
                                                    styleClass: '',
                                                    routerLink: [
                                                        '/admin/reimbursement/settings/medical-rule-detail/list',
                                                    ],
                                                },
                                                {
                                                    label: 'Medical Period',
                                                    routerLink: [
                                                        '/admin/reimbursement/settings/medical-period/list',
                                                    ],
                                                },
                                            ],
                                        },
                                        // HIDE SEMENTARA (SA & BA)
                                        // {
                                        //     label: 'Employee Reimburse History',
                                        //     styleClass: '',
                                        //     routerLink: [
                                        //         '/admin/reimbursement/settings/employee-reimburse-history/list',
                                        //     ],
                                        // },
                                        {
                                            label: 'Medical Expense',
                                            styleClass: '',
                                            routerLink: [
                                                '/admin/reimbursement/settings/medical-expense/list',
                                            ],
                                        },
                                        {
                                            label: 'Disease Master',
                                            styleClass: '',
                                            routerLink: [
                                                '/admin/reimbursement/settings/disease/list',
                                            ],
                                        },
                                    ],
                                },
                                {
                                    label: 'Donation',
                                    styleClass: '',
                                    items: [
                                        {
                                            label: 'Disaster Master',
                                            styleClass: '',
                                            routerLink: [
                                                '/admin/reimbursement/settings/disaster-types/list',
                                            ],
                                        },
                                        {
                                            label: 'Donation Type',
                                            styleClass: '',
                                            routerLink: [
                                                '/admin/reimbursement/settings/donation-type/list',
                                            ],
                                        },
                                        {
                                            label: 'Donation Rule',
                                            styleClass: '',
                                            routerLink: [
                                                '/admin/reimbursement/settings/donation-rule/list',
                                            ],
                                        },
                                        {
                                            label: 'Donation Rule Detail',
                                            styleClass: '',
                                            routerLink: [
                                                '/admin/reimbursement/settings/donation-rule-detail/list',
                                            ],
                                        },
                                    ],
                                },
                                {
                                    label: 'Business Trip',
                                    styleClass: '',
                                    items: [
                                        {
                                            label: 'Route Master',
                                            styleClass: '',
                                            routerLink: [
                                                '/admin/reimbursement/settings/route-master/list',
                                            ],
                                        },
                                        {
                                            label: 'Business Trip Expense',
                                            styleClass: '',
                                            routerLink: [
                                                '/admin/reimbursement/settings/business-expense-group/list',
                                            ],
                                        },
                                        {
                                            label: 'Business Trip Rule',
                                            styleClass: '',
                                            routerLink: [
                                                '/admin/reimbursement/settings/business-trip-rule/list',
                                            ],
                                        },
                                    ],
                                },
                                {
                                    label: 'Hospital rule',
                                    styleClass: '',
                                    routerLink: [
                                        '/admin/reimbursement/settings/hospital-rule/list',
                                    ],
                                },
                            ],
                        },
                        {
                            label: 'Activities',
                            styleClass: '',
                            items: [
                                {
                                    label: 'Reimbursement Annual Limit',
                                    styleClass: '',
                                    items: [
                                        {
                                            label: 'Annual Limit Process',
                                            styleClass: '',
                                            routerLink: [
                                                '/admin/reimbursement/activities/annual-limit-process/list',
                                            ],
                                        },
                                        {
                                            label: 'Annual Limit Process Result',
                                            styleClass: '',
                                            routerLink: [
                                                '/admin/reimbursement/activities/annual-limit-result/list',
                                            ],
                                        },
                                    ],
                                },
                                {
                                    label: 'Medical Reimbursement Request',
                                    styleClass: '',
                                    routerLink: [
                                        '/admin/reimbursement/activities/medical-reimbursement-request/list',
                                    ],
                                },
                                {
                                    label: 'Medical Reimbursement Payment',
                                    styleClass: '',
                                    items: [
                                        {
                                            label: 'Payment By Payroll',
                                            styleClass: '',
                                            routerLink: [
                                                '/admin/reimbursement/activities/medical-reimbursement-payment/by-payroll/list',
                                            ],
                                        },
                                        {
                                            label: 'Payment By Cash',
                                            styleClass: '',
                                            routerLink: [
                                                '/admin/reimbursement/activities/medical-reimbursement-payment/by-cash/list',
                                            ],
                                        },
                                    ],
                                },
                                {
                                    label: 'Donation',
                                    styleClass: '',
                                    items: [
                                        {
                                            label: 'Donation Request',
                                            styleClass: '',
                                            routerLink: [
                                                '/admin/reimbursement/activities/donation-request/list',
                                            ],
                                        },
                                    ],
                                },
                                {
                                    label: 'Business Trip',
                                    styleClass: '',
                                    items: [
                                        {
                                            label: 'Business Trip Request',
                                            styleClass: '',
                                            routerLink: [
                                                '/admin/reimbursement/activities/business-trip-request/list',
                                            ],
                                        },
                                        {
                                            label: 'Business Trip Settlement',
                                            styleClass: '',
                                            routerLink: [
                                                '/admin/reimbursement/activities/business-trip-settlement/list',
                                            ],
                                        },
                                    ],
                                },
                                {
                                    label: 'Hospital Invoice',
                                    styleClass: '',
                                    items: [
                                        {
                                            label: 'Hospital Invoice Request',
                                            styleClass: '',
                                            routerLink: [
                                                '/admin/reimbursement/activities/hospital-invoice-request/list',
                                            ],
                                        },
                                    ],
                                },
                            ],
                        },
                        {
                            label: 'Reports',
                            styleClass: '',
                            items: [
                                {
                                    label: 'Medical Report',
                                    styleClass: '',
                                    items: [
                                        {
                                            label: 'Detail Medical Report',
                                            styleClass: '',
                                            routerLink: [
                                                '/admin/reimbursement/reports/medical',
                                            ],
                                        },
                                        {
                                            label: 'Summary Medical Report',
                                            styleClass: '',
                                            routerLink: [
                                                '/admin/reimbursement/reports/summary-medical',
                                            ],
                                        },
                                    ],
                                },
                                {
                                    label: 'Hospital Invoice',
                                    styleClass: '',
                                    items: [
                                        {
                                            label: 'Hospital Garanty Letter',
                                            styleClass: '',
                                            routerLink: [
                                                '/admin/reimbursement/reports/hospital-garanty-letter',
                                            ],
                                        },
                                        {
                                            label: 'Hospital Invoice Recapitulation Report',
                                            styleClass: '',
                                            routerLink: [
                                                '/admin/reimbursement/reports/hospital-invoice-recapitulation',
                                            ],
                                        },
                                    ],
                                },
                                {
                                    label: 'Donation',
                                    styleClass: '',
                                    items: [
                                        {
                                            label: 'Detail Donation Report',
                                            styleClass: '',
                                            routerLink: [
                                                '/admin/reimbursement/reports/donation',
                                            ],
                                        },
                                        {
                                            label: 'Summary Education Report',
                                            styleClass: '',
                                            routerLink: [
                                                '/admin/reimbursement/reports/summary-education',
                                            ],
                                        },
                                        {
                                            label: 'Summary Recreation Report',
                                            styleClass: '',
                                            routerLink: [
                                                '/admin/reimbursement/reports/summary-recreation',
                                            ],
                                        },
                                        {
                                            label: 'Donation Memorandum',
                                            styleClass: '',
                                            routerLink: [
                                                '/admin/reimbursement/reports/donation-memorandum',
                                            ],
                                        },
                                    ],
                                },
                                {
                                    label: 'Business Trip Report',
                                    styleClass: '',
                                    items: [
                                        {
                                            label: 'Business Trip Cost Report',
                                            styleClass: '',
                                            routerLink: [
                                                '/admin/reimbursement/reports/business-trip',
                                            ],
                                        },
                                        {
                                            label: 'Summary SPPD Report',
                                            styleClass: '',
                                            routerLink: [
                                                '/admin/reimbursement/reports/summary-sppd',
                                            ],
                                        },
                                    ],
                                },
                                {
                                    label: 'Hospital Invoice Report',
                                    styleClass: '',
                                    routerLink: [
                                        '/admin/reimbursement/reports/hospital-invoice',
                                    ],
                                },
                                {
                                    label: 'Service Periode Report',
                                    styleClass: '',
                                    routerLink: [
                                        '/admin/reimbursement/reports/service-periode',
                                    ],
                                },
                                {
                                    label: 'Report to BOD',
                                    styleClass: '',
                                    routerLink: [
                                        '/admin/reimbursement/reports/to-bod',
                                    ],
                                },
                            ],
                        },
                    ],
                },
            ],
        },
        {
            items: [
                {
                    label: 'Payroll',
                    icon: 'fas fa-money-bill',
                    routerLink: ['/admin/payroll'],
                    subMenu: [
                        {
                            label: 'Settings',
                            styleClass: '',
                            items: [
                                {
                                    label: 'General Settings',
                                    styleClass: '',
                                    items: [
                                        // {
                                        //     label: 'Company Insurances',
                                        //     styleClass: '',
                                        //     routerLink: [
                                        //         '/admin/payroll/settings/company-insurances/list',
                                        //     ],
                                        // },
                                        // {
                                        //     label: 'Payroll Policy',
                                        //     styleClass: '',
                                        //     routerLink: [
                                        //         '/admin/payroll/settings/payroll-policy/list',
                                        //     ],
                                        // },
                                        {
                                            label: 'Currency Rate',
                                            styleClass: '',
                                            routerLink: [
                                                '/admin/payroll/settings/currency-rate/list',
                                            ],
                                        },
                                    ],
                                },
                                {
                                    label: 'Bank',
                                    styleClass: '',
                                    items: [
                                        {
                                            label: 'Company Bank',
                                            styleClass: '',
                                            routerLink: [
                                                '/admin/payroll/settings/bank/company-bank/list',
                                            ],
                                        },
                                        {
                                            label: 'Employee Bank',
                                            styleClass: '',
                                            routerLink: [
                                                '/admin/payroll/settings/employee-bank/list',
                                            ],
                                        },
                                    ],
                                },
                                {
                                    label: 'Payroll',
                                    styleClass: '',
                                    items: [
                                        {
                                            label: 'Payroll Component',
                                            styleClass: '',
                                            routerLink: [
                                                '/admin/payroll/settings/payroll-component/list',
                                            ],
                                        },
                                        {
                                            label: 'Payroll Group',
                                            styleClass: '',
                                            routerLink: [
                                                '/admin/payroll/settings/payroll-group/list',
                                            ],
                                        },
                                        {
                                            label: 'Retroactive Component Mapping',
                                            styleClass: '',
                                            routerLink: [
                                                '/admin/payroll/settings/retroactive-mapping/list',
                                            ],
                                        },
                                    ],
                                },
                                {
                                    label: 'Component Calculation',
                                    styleClass: '',
                                    items: [
                                        {
                                            label: 'Criteria Allowance',
                                            styleClass: '',
                                            routerLink: [
                                                '/admin/payroll/settings/criteria-allowance/list',
                                            ],
                                        },
                                        {
                                            label: 'Schedule Allowance',
                                            styleClass: '',
                                            routerLink: [
                                                '/admin/payroll/settings/schedule-allowance/list',
                                            ],
                                        },
                                        {
                                            label: 'Service Period Setup',
                                            styleClass: '',
                                            routerLink: [
                                                '/admin/payroll/settings/service-period-setup/list',
                                            ],
                                        },
                                        {
                                            label: 'Service Period Allowance',
                                            styleClass: '',
                                            routerLink: [
                                                '/admin/payroll/settings/service-period-allowance/list',
                                            ],
                                        },
                                        {
                                            label: 'Formula Allowance',
                                            styleClass: '',
                                            routerLink: [
                                                '/admin/payroll/settings/formula-allowance/list',
                                            ],
                                        },
                                    ],
                                },
                                {
                                    label: 'Bonus & Allowance',
                                    styleClass: '',
                                    items: [
                                        {
                                            label: 'Performance Index Allowance',
                                            styleClass: '',
                                            routerLink: [
                                                '/admin/payroll/settings/performance-index-allowance/list',
                                            ],
                                        },
                                        {
                                            label: 'Bonus & Allowance Setting',
                                            styleClass: '',
                                            routerLink: [
                                                '/admin/payroll/settings/bonus-and-allowance-setting/list',
                                            ],
                                        },
                                    ],
                                },

                                {
                                    label: 'Severance Component',
                                    styleClass: '',
                                    routerLink: [
                                        '/admin/payroll/settings/severance-component/list',
                                    ],
                                },
                                {
                                    label: 'Tax',
                                    styleClass: '',
                                    items: [
                                        {
                                            label: 'Tax Location',
                                            styleClass: '',
                                            routerLink: [
                                                '/admin/payroll/settings/tax-location/list',
                                            ],
                                        },
                                        {
                                            label: 'Company Tax',
                                            styleClass: '',
                                            routerLink: [
                                                '/admin/payroll/settings/tax/company-tax/list',
                                            ],
                                        },
                                        {
                                            label: 'Tax Group',
                                            styleClass: '',
                                            routerLink: [
                                                '/admin/payroll/settings/tax-group/list',
                                            ],
                                        },
                                        {
                                            label: 'Tax Setup',
                                            styleClass: '',
                                            routerLink: [
                                                '/admin/payroll/settings/tax-setup/list',
                                            ],
                                        },
                                        {
                                            label: 'Tax Effective Rate Mapping',
                                            styleClass: '',
                                            routerLink: [
                                                '/admin/payroll/settings/tax-rate/list',
                                            ],
                                        },
                                    ],
                                },
                                {
                                    label: 'BPJS',
                                    styleClass: '',
                                    items: [
                                        {
                                            label: 'BPJS Office',
                                            styleClass: '',
                                            routerLink: [
                                                '/admin/payroll/settings/bpjs-office/list-ketenagakerjaan',
                                            ],
                                        },
                                        {
                                            label: 'BPJS KS Company',
                                            styleClass: '',
                                            routerLink: [
                                                '/admin/payroll/settings/bpjs-ks-company/list',
                                            ],
                                        },
                                        {
                                            label: 'BPJS TK Company',
                                            styleClass: '',
                                            routerLink: [
                                                '/admin/payroll/settings/bpjs-tk-company/list',
                                            ],
                                        },
                                        {
                                            label: 'BPJS Group',
                                            styleClass: '',
                                            routerLink: [
                                                '/admin/payroll/settings/bpjs-group/kesehatan/list',
                                            ],
                                        },
                                    ],
                                },

                                {
                                    label: 'Loan',
                                    styleClass: '',
                                    items: [
                                        {
                                            label: 'Loan Rules Configuration',
                                            styleClass: '',
                                            routerLink: [
                                                '/admin/payroll/settings/loan-rules-configuration/list',
                                            ],
                                        },
                                        {
                                            label: 'Loan Whitelist Reason',
                                            styleClass: '',
                                            routerLink: [
                                                '/admin/payroll/settings/loan-whitelists-reason/list',
                                            ],
                                        },
                                        {
                                            label: 'Loan Type',
                                            styleClass: '',
                                            routerLink: [
                                                '/admin/payroll/settings/loan/loan-type/list',
                                            ],
                                        },
                                    ],
                                },
                                {
                                    label: 'Employee Minimum Wage (UMK)',
                                    styleClass: '',
                                    routerLink: [
                                        '/admin/payroll/settings/employee-minimum-wage/list',
                                    ],
                                },
                            ],
                        },
                        {
                            label: 'Activities',
                            styleClass: '',
                            items: [
                                {
                                    label: 'Salary Decision',
                                    styleClass: '',
                                    routerLink: [
                                        '/admin/payroll/activities/salary-decision/list',
                                    ],
                                },
                                {
                                    label: 'Schedule Allowance Process',
                                    styleClass: '',
                                    routerLink: [
                                        '/admin/payroll/activities/schedule-allowance-process/list',
                                    ],
                                },
                                {
                                    label: 'Monthly Process',
                                    styleClass: '',
                                    items: [
                                        {
                                            label: 'Monthly Editable',
                                            styleClass: '',
                                            routerLink: [
                                                '/admin/payroll/activities/monthly-editable/list',
                                            ],
                                        },
                                        {
                                            label: 'Payroll Process',
                                            styleClass: '',
                                            routerLink: [
                                                '/admin/payroll/activities/monthly-process/payroll-process/list',
                                            ],
                                        },
                                        {
                                            label: 'Payroll Payment Process',
                                            styleClass: '',
                                            routerLink: [
                                                '/admin/payroll/activities/monthly-process/payroll-payment-process/list',
                                            ],
                                        },
                                    ],
                                },
                                {
                                    label: 'Bonus & Allowance Process',
                                    styleClass: '',
                                    items: [
                                        {
                                            label: 'Bonus & Allowance Editable',
                                            styleClass: '',
                                            routerLink: [
                                                '/admin/payroll/activities/bonus-and-allowance-editable/employee/list',
                                            ],
                                        },
                                        {
                                            label: 'Bonus & Allowance Process',
                                            styleClass: '',
                                            routerLink: [
                                                '/admin/payroll/activities/bonus-and-allowance-process/list',
                                            ],
                                        },
                                        {
                                            label: 'Bonus & Allowance Payment Process',
                                            styleClass: '',
                                            routerLink: [
                                                '/admin/payroll/activities/bonus-and-allowance-payment-process/list',
                                            ],
                                        },
                                    ],
                                },
                                {
                                    label: 'Retroactive Process',
                                    styleClass: '',
                                    routerLink: [
                                        '/admin/payroll/activities/retroactive-process/list',
                                    ],
                                },
                                {
                                    label: 'BPJS',
                                    styleClass: '',
                                    items: [
                                        {
                                            label: 'BPJS TK Employee Administration',
                                            styleClass: '',
                                            routerLink: [
                                                '/admin/payroll/activities/bpjs-tk-employee-adm/list',
                                            ],
                                        },
                                        {
                                            label: 'BPJS KS Employee Administration',
                                            styleClass: '',
                                            routerLink: [
                                                '/admin/payroll/activities/bpjs-ks-employee-adm/list',
                                            ],
                                        },
                                    ],
                                },
                                {
                                    label: 'Severance Process',
                                    styleClass: '',
                                    items: [
                                        {
                                            label: 'Severance Process Editable',
                                            styleClass: '',
                                            routerLink: [
                                                '/admin/payroll/activities/severance-process-editable/list',
                                            ],
                                        },
                                        {
                                            label: 'Severance Process',
                                            styleClass: '',
                                            routerLink: [
                                                '/admin/payroll/activities/severance-process/list',
                                            ],
                                        },
                                        {
                                            label: 'Severance Payment Process',
                                            styleClass: '',
                                            routerLink: [
                                                '/admin/payroll/activities/severance-payment-process/list',
                                            ],
                                        },
                                    ],
                                },
                                {
                                    label: 'Tax',
                                    styleClass: '',
                                    items: [
                                        {
                                            label: 'Tax Administration Employee',
                                            styleClass: '',
                                            routerLink: [
                                                '/admin/payroll/activities/tax-employee-administration/list',
                                            ],
                                        },
                                        // {
                                        //     label: 'Tax History',
                                        //     styleClass: '',
                                        //     routerLink: [
                                        //         '/admin/payroll/activities/tax-history/list',
                                        //     ],
                                        // },
                                        // {
                                        //     label: 'Tax Migration',
                                        //     styleClass: '',
                                        //     routerLink: [
                                        //         '/admin/payroll/activities/tax-migration/list',
                                        //     ],
                                        // },
                                    ],
                                },
                                {
                                    label: 'Loan',
                                    styleClass: '',
                                    items: [
                                        {
                                            label: 'Loan Request',
                                            styleClass: '',
                                            routerLink: [
                                                '/admin/payroll/activities/loan-request/list',
                                            ],
                                        },
                                        {
                                            label: 'Loan Rescheduling',
                                            styleClass: '',
                                            routerLink: [
                                                '/admin/payroll/activities/loan-rescheduling/list',
                                            ],
                                        },
                                        {
                                            label: 'Loan Installments',
                                            styleClass: '',
                                            routerLink: [
                                                '/admin/payroll/activities/loan-installments/list-payroll',
                                            ],
                                        },
                                        {
                                            label: 'Loan Whitelist',
                                            styleClass: '',
                                            routerLink: [
                                                '/admin/payroll/activities/loan-whitelist/list',
                                            ],
                                        },
                                    ],
                                },
                            ],
                        },
                        {
                            label: 'Reports',
                            styleClass: '',
                            items: [
                                {
                                    label: 'Payroll',
                                    styleClass: '',
                                    items: [
                                        {
                                            label: 'Payroll Detail',
                                            styleClass: '',
                                            routerLink: [
                                                '/admin/payroll/reports/payroll-details',
                                            ],
                                        },
                                        {
                                            label: 'Payroll Summary',
                                            styleClass: '',
                                            routerLink: [
                                                '/admin/payroll/reports/payroll-summary',
                                            ],
                                        },
                                        {
                                            label: 'Accumulation Payroll Detail',
                                            styleClass: '',
                                            routerLink: [
                                                '/admin/payroll/reports/accumulation-payroll',
                                            ],
                                        },
                                        {
                                            label: 'Salary Summary Group Cost',
                                            styleClass: '',
                                            routerLink: [
                                                '/admin/payroll/reports/summary-cost',
                                            ],
                                        },
                                        {
                                            label: 'Individual Salary',
                                            styleClass: '',
                                            routerLink: [
                                                '/admin/payroll/reports/individual-salary',
                                            ],
                                        },
                                        {
                                            label: 'MCM Report',
                                            styleClass: '',
                                            routerLink: [
                                                '/admin/payroll/reports/mcm',
                                            ],
                                        },
                                        {
                                            label: 'Year To Date',
                                            styleClass: '',
                                            routerLink: [
                                                '/admin/payroll/reports/year-to-date',
                                            ],
                                        },
                                    ],
                                },
                                {
                                    label: 'Salary Slip',
                                    styleClass: '',
                                    routerLink: [
                                        '/admin/payroll/reports/salary-slip',
                                    ],
                                },
                                {
                                    label: 'Bonus Report',
                                    styleClass: '',
                                    routerLink: [
                                        '/admin/payroll/reports/bonus',
                                    ],
                                },
                                {
                                    label: 'Compensation Leave Report',
                                    styleClass: '',
                                    routerLink: [
                                        '/admin/payroll/reports/compensation-leave',
                                    ],
                                },
                                {
                                    label: 'Tax',
                                    styleClass: '',
                                    items: [
                                        {
                                            label: 'Tax Report',
                                            styleClass: '',
                                            routerLink: [
                                                '/admin/payroll/reports/tax',
                                            ],
                                        },
                                        {
                                            label: 'E-Bupot Pph 21',
                                            styleClass: '',
                                            routerLink: [
                                                '/admin/payroll/reports/e-bupot-pph-21',
                                            ],
                                        },
                                        {
                                            label: 'E-Bupot 21 A1',
                                            styleClass: '',
                                            routerLink: [
                                                '/admin/payroll/reports/ebupot21-a1',
                                            ],
                                        },
                                    ],
                                },
                                {
                                    label: 'BPJS Ketenagakerjaan',
                                    styleClass: '',
                                    routerLink: [
                                        '/admin/payroll/reports/bpjs-tk',
                                    ],
                                },
                                {
                                    label: 'Loan Report',
                                    styleClass: '',
                                    routerLink: ['/admin/payroll/reports/loan'],
                                },
                            ],
                        },
                    ],
                },
            ],
        },
        {
            items: [
                {
                    label: 'Recruitment',
                    icon: 'fas fa-user-plus',
                    routerLink: ['/admin/recruitment'],
                    subMenu: [
                        {
                            label: 'Settings',
                            styleClass: '',
                            items: [
                                {
                                    label: 'Activity Stage',
                                    styleClass: '',
                                    routerLink: [
                                        '/admin/recruitment/settings/activity-stage/list',
                                    ],
                                },
                                {
                                    label: 'Template Stage',
                                    styleClass: '',
                                    routerLink: [
                                        '/admin/recruitment/settings/template-stage/list',
                                    ],
                                },
                                {
                                    label: 'Replaced Caused',
                                    styleClass: '',
                                    routerLink: [
                                        '/admin/recruitment/settings/replaced-caused/list',
                                    ],
                                },
                                {
                                    label: 'Career Path',
                                    styleClass: '',
                                    routerLink: [
                                        '/admin/recruitment/settings/career-path/list',
                                    ],
                                },
                                {
                                    label: 'Master Tag',
                                    styleClass: '',
                                    routerLink: [
                                        '/admin/recruitment/settings/master-tag/list',
                                    ],
                                },
                                {
                                    label: 'Job Vacancy Reason',
                                    styleClass: '',
                                    routerLink: [
                                        '/admin/recruitment/settings/job-vacancy-reason/list',
                                    ],
                                },
                                {
                                    label: 'Q&A',
                                    styleClass: '',
                                    routerLink: [
                                        '/admin/recruitment/settings/qna/list',
                                    ],
                                },
                                {
                                    label: 'Job Vacancy Template',
                                    styleClass: '',
                                    routerLink: [
                                        '/admin/recruitment/settings/job-vacancy-template/list',
                                    ],
                                },
                            ],
                        },
                        {
                            label: 'Activities',
                            styleClass: '',
                            items: [
                                {
                                    label: 'Employee Request',
                                    styleClass: '',
                                    routerLink: [
                                        '/admin/recruitment/activities/employee-request/list',
                                    ],
                                },
                                {
                                    label: 'Job Vacancy',
                                    styleClass: '',
                                    routerLink: [
                                        '/admin/recruitment/activities/job-vacancy/list',
                                    ],
                                },
                                {
                                    label: 'Event',
                                    styleClass: '',
                                    routerLink: [
                                        '/admin/recruitment/activities/event/list',
                                    ],
                                },
                                {
                                    label: 'Offering',
                                    styleClass: '',
                                    routerLink: [
                                        '/admin/recruitment/activities/offering/list',
                                    ],
                                },
                                {
                                    label: 'Hiring',
                                    styleClass: '',
                                    routerLink: [
                                        '/admin/recruitment/activities/hiring/list',
                                    ],
                                },
                            ],
                        },
                        {
                            label: 'Report',
                            styleClass: '',
                            items: [
                                {
                                    label: 'Recruitment Progress',
                                    styleClass: '',
                                    routerLink: [''],
                                },
                                {
                                    label: 'Candidate Report',
                                    styleClass: '',
                                    routerLink: [''],
                                },
                            ],
                        },
                    ],
                },
            ],
        },
        {
            items: [
                {
                    label: 'Competency',
                    icon: 'fas fa-chart-simple',
                    routerLink: ['/admin/competency'],
                    subMenu: [
                        {
                            label: 'Settings',
                            styleClass: '',
                            items: [
                                {
                                    label: 'Configurations',
                                    styleClass: '',
                                    routerLink: [
                                        '/admin/competency/settings/configurations/list',
                                    ],
                                },
                                {
                                    label: 'Domain',
                                    styleClass: '',
                                    routerLink: [
                                        '/admin/competency/settings/domain/list',
                                    ],
                                },
                                {
                                    label: 'Aspect',
                                    styleClass: '',
                                    routerLink: [
                                        '/admin/competency/settings/aspect/list',
                                    ],
                                },
                                {
                                    label: 'Proficiency',
                                    styleClass: '',
                                    routerLink: [
                                        '/admin/competency/settings/proficiency/list',
                                    ],
                                },
                                {
                                    label: 'Competency List',
                                    styleClass: '',
                                    routerLink: [
                                        '/admin/competency/settings/competencies/list',
                                    ],
                                },
                                {
                                    label: 'Competency Aspect',
                                    styleClass: '',
                                    routerLink: [
                                        '/admin/competency/settings/competency-aspect/list',
                                    ],
                                },
                                {
                                    label: 'Competency Assignment',
                                    styleClass: '',
                                    routerLink: [
                                        '/admin/competency/settings/competency-assignment/list',
                                    ],
                                },
                                {
                                    label: 'Competency Indicator / Behavior',
                                    styleClass: '',
                                    routerLink: [
                                        '/admin/competency/settings/competency-indicator/list',
                                    ],
                                },
                            ],
                        },
                    ],
                },
            ],
        },
        {
            items: [
                {
                    label: 'Performance',
                    icon: 'fas fa-chart-line',
                    routerLink: ['/admin/performance'],
                    subMenu: [
                        {
                            label: 'Settings',
                            styleClass: '',
                            items: [
                                {
                                    label: 'General Setting',
                                    styleClass: '',
                                    routerLink: [
                                        '/admin/performance/settings/configurations/list',
                                    ],
                                },
                                {
                                    label: 'Period',
                                    styleClass: '',
                                    routerLink: [
                                        '/admin/performance/settings/parameter/list',
                                    ],
                                },
                                {
                                    label: 'Measurements',
                                    styleClass: '',
                                    items: [
                                        {
                                            label: 'P3JJ Value',
                                            styleClass: '',
                                            routerLink: [
                                                '/admin/performance/settings/masterp3jj/list',
                                            ],
                                        },
                                        {
                                            label: 'Score Value',
                                            styleClass: '',
                                            routerLink: [
                                                '/admin/performance/settings/score-measurement/data-scoring/list',
                                            ],
                                        },
                                    ],
                                },
                                {
                                    label: 'Result',
                                    styleClass: '',
                                    routerLink: [
                                        '/admin/performance/settings/result/list',
                                    ],
                                },
                                {
                                    label: 'Form',
                                    styleClass: '',
                                    items: [
                                        {
                                            label: 'Form Template',
                                            styleClass: '',
                                            routerLink: [
                                                '/admin/performance/settings/form-template/list',
                                            ],
                                        },
                                        {
                                            label: 'Assign form',
                                            styleClass: '',
                                            routerLink: [
                                                '/admin/performance/settings/assign-form/list',
                                            ],
                                        },
                                    ],
                                },
                            ],
                        },
                        {
                            label: 'Activities',
                            styleClass: '',
                            items: [
                                {
                                    label: 'Competency Review',
                                    styleClass: '',
                                    items: [
                                        {
                                            label: 'Form Review',
                                            styleClass: '',
                                            routerLink: [
                                                '/admin/performance/activities/form-review/list',
                                            ],
                                        },
                                        {
                                            label: 'Calibration Review',
                                            styleClass: '',
                                            routerLink: [
                                                '/admin/performance/activities/calibration-review/list',
                                            ],
                                        },
                                    ],
                                },
                                {
                                    label: 'Review Data Process',
                                    styleClass: '',
                                    items: [
                                        {
                                            label: 'Form 149 Process',
                                            styleClass: '',
                                            routerLink: [
                                                '/admin/performance/activities/form-149-process/list',
                                            ],
                                        },
                                        {
                                            label: 'Form 17 Process',
                                            styleClass: '',
                                            routerLink: [
                                                '/admin/performance/activities/form-17-process/list',
                                            ],
                                        },
                                        {
                                            label: 'Form Managerial Process',
                                            styleClass: '',
                                            routerLink: [
                                                '/admin/performance/activities/form-manajerial/list',
                                            ],
                                        },
                                        {
                                            label: 'Form Non-Managerial Process',
                                            styleClass: '',
                                            routerLink: [
                                                '/admin/performance/activities/form-non-manajerial/list',
                                            ],
                                        },
                                    ],
                                },
                            ],
                        },
                        {
                            label: 'Reports',
                            styleClass: '',
                            items: [
                                {
                                    label: 'Competency Review Report',
                                    styleClass: '',
                                    routerLink: [
                                        '/admin/performance/reports/competency-review',
                                    ],
                                },
                            ]
                        }
                    ],
                },
            ],
        },
        {
            items: [
                {
                    label: 'Loan',
                    icon: 'fas fa-credit-card',
                    routerLink: ['/showcase'],
                },
            ],
        },
        {
            items: [
                {
                    label: 'Career Path',
                    icon: 'fas fa-map-signs',
                    routerLink: ['/showcase'],
                },
            ],
        },
        {
            items: [
                {
                    label: 'Learning Management System',
                    icon: 'fas fa-graduation-cap',
                    routerLink: ['/admin/lnd'],
                    subMenu: [
                        {
                            label: 'Settings',
                            styleClass: '',
                            items: [
                                {
                                    label: 'Learning Period',
                                    styleClass: '',
                                    routerLink: [
                                        '/admin/lnd/settings/training-period/list',
                                    ],
                                },
                                {
                                    label: 'Provider',
                                    styleClass: '',
                                    routerLink: [
                                        '/admin/lnd/settings/provider/list',
                                    ],
                                },
                                {
                                    label: 'Venue',
                                    styleClass: '',
                                    routerLink: [
                                        '/admin/lnd/settings/location-type/list',
                                    ],
                                },
                                {
                                    label: 'Learning List',
                                    styleClass: '',
                                    routerLink: [
                                        '/admin/lnd/settings/training-list/list',
                                    ],
                                },
                                {
                                    label: 'Topic',
                                    styleClass: '',
                                    routerLink: [
                                        '/admin/lnd/settings/topic/list',
                                    ],
                                },
                                {
                                    label: 'Course',
                                    styleClass: '',
                                    routerLink: [
                                        '/admin/lnd/settings/course/list',
                                    ],
                                },
                                {
                                    label: 'Instructor',
                                    styleClass: '',
                                    routerLink: [
                                        '/admin/lnd/settings/internal-instructor/list',
                                    ],
                                },
                                {
                                    label: 'Learning PIC',
                                    styleClass: '',
                                    routerLink: [
                                        '/admin/lnd/settings/learning-pic/list',
                                    ],
                                },
                                {
                                    label: 'Test Master',
                                    styleClass: '',
                                    routerLink: [
                                        '/admin/lnd/settings/test-form-template/list',
                                    ],
                                },
                                {
                                    label: 'Evaluation Form',
                                    styleClass: '',
                                    items: [
                                        {
                                            label: 'Score Value',
                                            styleClass: '',
                                            routerLink: [
                                                '/admin/lnd/settings/score-value/list',
                                            ],
                                        },
                                        {
                                            label: 'Evaluation Form',
                                            styleClass: '',
                                            routerLink: [
                                                '/admin/lnd/settings/evaluation-form/list'
                                            ],
                                        },
                                    ],
                                },

                            ],
                        },
                        {
                            label: 'Activities',
                            styleClass: '',
                            items: [
                                {
                                    label: 'Learning Plan',
                                    styleClass: '',
                                    routerLink: [
                                        '/admin/lnd/activities/learning-plan/list',
                                    ],
                                },
                                {
                                    label: 'Learning Request',
                                    styleClass: '',
                                    items: [
                                        {
                                            label: 'Learning Plan Request',
                                            styleClass: '',
                                            routerLink: [
                                                '/admin/lnd/activities/learning-request/learning-plan-request/list',
                                            ],
                                        },
                                    ],
                                },
                            ],
                        },
                        {
                            label: 'Reports',
                            styleClass: '',
                            items: [
                                {
                                    label: 'Learning Summary',
                                    styleClass: '',
                                    routerLink: [
                                        '/admin/lnd/reports/learning-summary/list',
                                    ],
                                },
                                {
                                    label: 'Learning Individual',
                                    styleClass: '',
                                    routerLink: [
                                        '/admin/lnd/reports/learning-individual/list',
                                    ],
                                },
                                {
                                    label: 'Learning Plan',
                                    styleClass: '',
                                    routerLink: [
                                        '/admin/lnd/reports/learning-plan/list',
                                    ],
                                },
                                {
                                    label: 'Plan & Actual Learning',
                                    styleClass: '',
                                    routerLink: [
                                        '/admin/lnd/reports/plan-actual-learning/list',
                                    ],
                                },
                                {
                                    label: 'Plan & Actual Budget Learning',
                                    styleClass: '',
                                    routerLink: [
                                        '/admin/lnd/reports/plan-actual-budget-learning/list',
                                    ],
                                },
                            ],
                        },
                    ],
                },
            ],
        },
        {
            items: [
                {
                    label: 'Log History',
                    icon: 'fa-solid fa-clock-rotate-left',
                    routerLink: ['/admin/log-history'],
                    subMenu: [
                        {
                            menuId: '21f94e90-812d-4890-a3e2-ac0c6d4617cf',
                            label: 'Log History',
                            styleClass: '',
                            items: [
                                {
                                    menuId: '7b07f5a8-5d51-4c24-bad9-5da794fa05cf',
                                    label: 'Log History Settings',
                                    styleClass: '',
                                    routerLink: ['/admin/log-history/settings'],
                                },
                                {
                                    menuId: '21f94e90-812d-4890-a3e2-ac0c6d4617cf',
                                    label: 'Log History List',
                                    styleClass: '',
                                    routerLink: ['/admin/log-history/list'],
                                },
                            ],
                        },
                    ],
                },
            ],
        },
        {
            items: [
                {
                    label: 'Notifications',
                    routerLink: ['/admin/notifications/list'],
                    subMenu: [],
                },
            ],
        },
    ];

    constructor(private route: Router, public auth: AuthenticationService) {}

    private _setupSubMenus(): void {
        this.hardCodedMenu = [];
        this.items = [];
        if (this.auth.getLocalStorage(AppConstant.menuAdmin)) {
            this.hardCodedMenu = this.auth.getLocalStorage(
                AppConstant.menuAdmin
            );
        }
    }

    public ngOnInit(): void {
        // TODO: Uncomment this for rbac
        this._setupSubMenus();
        let currentModule = this.route.url.split('/')[2];

        if (currentModule) {
            this.changeSubMenu(currentModule);
        } else {
            this.items = [];
        }
    }

    public changeSubMenu(currentModule): void {
        this.hardCodedMenu.forEach((element) => {
            let label = element.items[0]?.label.toLocaleLowerCase();

            if (label == 'time & attendance') {
                label = 'time';
            }

            if (label == 'training') {
                label = 'lnd';
            }
            if (label == 'organization design') {
                label = 'organization';
            }

            if (label == currentModule.toLocaleLowerCase()) {
                // @ts-ignore
                this.items = element.items[0]?.subMenu;
                this.resetStyleclass(0, this.items);
                this.recursiveMenu(this.items);
            }
        });
    }

    public onClickMenu(): void {
        this.recursiveMenu(this.items);
    }

    public async recursiveMenu(child, parent?, grandParent?): Promise<any> {
        this.activeUrl = this.route.url;

        child.forEach((element) => {
            if (element.items?.length > 0) {
                element.items.forEach((element2) => {
                    if (element2.items?.length > 0) {
                        this.recursiveMenu(element2.items, element2, element);
                    } else {
                        if (element2.routerLink[0] == this.activeUrl) {
                            this.resetStyleclass(0, this.items);
                            element2.styleClass = 'active';
                            element.styleClass = 'active';
                        }
                    }
                });
            } else {
                if (element.routerLink?.[0] == this.activeUrl) {
                    this.resetStyleclass(0, this.items);

                    element.styleClass = 'active';

                    if (parent) {
                        parent.styleClass = 'active';

                        if (grandParent) {
                            grandParent.styleClass = 'active';
                        }
                    }
                }
            }
        });
    }

    public resetStyleclass(idx = 0, arrData): void {
        for (let i = idx; i < arrData.length; i++) {
            arrData[i].styleClass = '';

            if (arrData[i].items) {
                this.resetStyleclass(0, arrData[i].items);
            }
        }
    }

    public ngOnDestroy(): void {
        this.sub.unsubscribe();
    }
}
